.container {
  
  background-image: url(https://images.unsplash.com/photo-1614587396292-8e7aefcee7f6);
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 100px);
  grid-auto-rows: 100px;
  
  padding: 20px;
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

/* Custom background */

/* ### */

body {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, 100px);
  grid-auto-rows: 100px;
  background: orange;
  padding: 20px;
}

.Door {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: white;
  /* border-left: 50px solid crimson;
  border-right: 50px solid crimson; */
  border-left: 50px solid lightsteelblue;
  border-right: 50px solid lightsteelblue;
  border-radius: 20px;
  transition: border-width 1s ease;
  /*font-family: 'Spicy Rice', cursive;*/
  font-size: 17px;
  text-align: center;
  
}

.open {
  border-width: 0;
  color: crimson;
}
